import React, { useState } from 'react';
import moment from 'moment';
import ImageS3 from '../common/ImageS3';
import _ from 'lodash';

const BidTableInfo = ({ bidInfo, trip }) => {
  const [openIndex, setOpenIndex] = useState();

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">No.</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">BID Id</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Fare</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Owner</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Driver</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Car model</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Car No.</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Time</th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {bidInfo?.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">{item._id}{['accepted', 'acceptedByDriver', 'running', 'completed'].includes(trip?.tripStatus) && trip?.acceptedBid?.bidId === item._id && <span className="text-green-500"> - Accepted</span>}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    ৳ {item.bidFare.amount}
                    {['accepted', 'acceptedByDriver', 'running', 'completed'].includes(trip?.tripStatus) && trip?.acceptedBid?.bidId === item._id &&
                      <div>
                        <span>Passenger: ৳{trip?.booking?.passenger?.bookingType !== 'fixed' ? _.round(item.bidFare.amount * trip?.booking?.passenger?.bookingValue / 100, 2) : trip?.booking?.passenger?.bookingValue}</span>
                        <br />
                        <span>Driver: ৳{trip?.booking?.driver?.bookingType !== 'fixed' ? _.round(item.bidFare.amount * trip?.booking?.driver?.bookingValue / 100, 2) : trip?.booking?.driver?.bookingValue}</span>
                      </div>
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">{item.bidBy.owner.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {item.bidBy.driver.name} - {" "}
                    <button
                      onClick={() => toggleAccordion(index)}
                      className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-900 dark:hover:text-indigo-300"
                    >
                      View Details
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {item.vehicleDetails.vehicle.carInfo.model} - {item.vehicleDetails.vehicle.carInfo.year}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {item.vehicleDetails.vehicle.registration.vehicleNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {moment(item.createdAt).format("DD MMM YYYY, h:mm:ss A")}
                  </td>
                </tr>
                {openIndex === index && (
                  <tr>
                    <td colSpan="8" className="px-6 py-4 bg-gray-50 dark:bg-gray-700">
                      <div className="flex space-x-4">
                        <div className="flex flex-col items-center justify-center shadow-lg p-2 bg-white dark:bg-gray-800 rounded w-40 min-w-40">
                          <span className="text-gray-700 dark:text-gray-300 mb-2">Profile</span>
                          <ImageS3 uri={item.bidBy.driver.profile} style="h-20 w-20 rounded" />
                        </div>
                        <div className="flex flex-col items-center justify-center shadow-lg p-2 bg-white dark:bg-gray-800 rounded w-40 min-w-40">
                          <span className="text-gray-700 dark:text-gray-300 mb-2">Driving License</span>
                          <ImageS3 uri={item.bidBy.driver.docs.drivingLicense.uri} style="h-20 w-20 rounded" />
                        </div>
                        <div className="flex flex-col items-center justify-center shadow-lg p-2 bg-white dark:bg-gray-800 rounded w-40 min-w-40">
                          <span className="text-gray-700 dark:text-gray-300 mb-2">NID</span>
                          <ImageS3 uri={item.bidBy.driver.docs.nidFont.uri} style="h-20 w-20 rounded" />
                        </div>
                      </div>
                      <div className="mt-4 space-y-2">
                        <p className="text-sm text-gray-600 dark:text-gray-400">ID: {item.bidBy.driver._id}</p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">Name: {item.bidBy.driver.name}</p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">Mobile: {item.bidBy.driver.mobile}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BidTableInfo;