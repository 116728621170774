import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { sideBarItems } from "../../utils";
import RefundTable from "../../components/Tables/Refund_table";
import RefundModal from '../../components/common/RefundModal';
import TripService from "../../services/TripService";
import _ from "lodash";

const Refund = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const [trips, setTrips] = useState([]);
  const [tripsTrips, setTripsTrips] = useState([]);
  const [trip, setTrip] = useState(null);
  const [transaction, setTransaction] = useState(null);
  useEffect(() => {
    init().catch();
  }, []);

  const showModal = (visibleConformation, trip, transaction) => {
    setVisibilityConformation(visibleConformation);
    setTrip(trip);
    setTransaction(transaction);
  }

  const init = async () => {
    // const res = await TripService.getTrips({ tripStatus: 'canceled' });
    const res = await TripService.getRefundableTrips();
    setTrips((_.isArray(res?.data) && res?.data.reverse()) || []);
    setTripsTrips((_.isArray(res?.data) && res?.data) || []);
  };

  const handleChangeSearch = (searchQuery) => {
    let tripsTrips = _.filter(trips, item => {
      return item?.canceledBy?.driver?.match(new RegExp(searchQuery, 'gi'))
        || item?.canceledBy?.passenger?.match(new RegExp(searchQuery, 'gi'))
        || item?.canceledBy?.passenger?.match(new RegExp(searchQuery, 'gi'))
        || item?.passenger?.match(new RegExp(searchQuery, 'gi'))
        || item?._id?.match(new RegExp(searchQuery, 'gi'))
        || item?.transactionFor?.trip?._id?.match(new RegExp(searchQuery, 'gi'))
        || item?._id?.match(new RegExp(searchQuery, 'gi'))
    });
    setTripsTrips(tripsTrips)
  }

  return (
    <Layout handleChangeSearch={handleChangeSearch} title={sideBarItems.refund.key}>
      {visibleConformation && (
        <RefundModal trip={trip} transaction={transaction} onClose={() => {
          setVisibilityConformation(false);
          init().catch()
        }} />
      )}
      <div className="grid grid-cols-1">
        <RefundTable
          trips={tripsTrips}
          setVisibilityConformation={showModal}
          visibleConformation={visibleConformation}
        />
      </div>
    </Layout>
  );
};
export default Refund;
