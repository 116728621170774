import React from "react"
import Layout from "../../components/Layout/index"
import { useState, useEffect } from 'react'
import AdminService from '../../services/admin/AdminService';
import OwnerTable from '../../components/Tables/owner_table'
import DriverInfo from "../../components/Tables/driver_manage_info"
import Conformation from "../../components/common/conformation"
import CarInfo from "../../components/common/car_info_model"
import OwnerInfomation from './ownerInfo'
import { sideBarItems } from '../../utils';
import _ from 'lodash';


const Owners = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [showOwnerInfo, setShowOwnerInfo] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState();
  const [carInfo, setCarInfo] = useState();
  const [isedit, setIsedit] = useState(true);



  const [showpopu, setShowpopu] = useState(true);

  const showpopuSection = () => {
    setShowpopu(true);

  }


  const ShowOwnerSection = (item) => {
    setShowOwnerInfo(true);
    setOwnerInfo(item);

  }

  useEffect(() => {
    initApi();

  }, [])

  const initApi = () => {
    AdminService.getOwners().then(data => {
      setOwners(data.data);
      setFilteredOwners(data.data);
    })
  }

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, item => {
      return item.mobile.match(new RegExp(searchQuery, 'gi'))
        || item.name.match(new RegExp(searchQuery, 'gi'))
        || item._id.match(new RegExp(searchQuery, 'gi'))
    });
    setFilteredOwners(filteredOwners)
  }
  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }
  const showVihicleInfo = (item) => {
    // alert(JSON.stringify(item));
    setVisibleModal(true);
    setCarInfo(item);
  }


  return (
    <Layout
      modal={<CarInfo handleModal={handleModal} ownerInfo={ownerInfo} carInfo={carInfo} />}
      visibleModal={visibleModal}
      handleModal={handleModal}
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.owner.key}>

      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <OwnerTable
            owners={filteredOwners}
            isedit={setIsedit}
            ShowOwnerSection={ShowOwnerSection} />

        </div>
        {showOwnerInfo &&
          <Modal onClose={() => setShowOwnerInfo(false)}>
            <OwnerInfomation
              ownerInfo={ownerInfo}
              setShowOwnerInfo={setShowOwnerInfo}
              refresh={initApi}
              showVihicleInfo={showVihicleInfo}
              showOwnerInfo={showOwnerInfo} />
          </Modal>
        }

      </div>

    </Layout>
  )
};

const Modal = ({ children, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="p-6">
      {children}
    </div>
  </div>
);
export default Owners;
