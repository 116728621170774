import React, { useState } from "react";
import Conformation from "../common/conformation";
import AuthService from "../../services/owner/AuthService";
import useAuthentication from "../../components/HOC/useAuthentication";
import ImageS3 from "../../components/common/ImageS3";

const DriverInfomation = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const { user } = useAuthentication();

  const getName = () => {
    let name = "# ";
    if (props?.driveinfo?.name) return props?.driveinfo?.name;
    props?.driveinfo?.myOwners.forEach((x, i) => {
      name = name + x?.name + " #";
    });
    return name;
  };

  const getProfile = () => {
    if (props?.driveinfo?.profile?.includes("digitaloceanspaces")) {
      return props?.driveinfo?.profile;
    }
    if (props?.driveinfo?.myOwners?.length > 0 && props?.driveinfo?.myOwners[0]?.profile) {
      return props?.driveinfo?.myOwners[0]?.profile;
    }
    return props?.driveinfo?.profile;
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 max-w-2xl mx-auto">
      {visibleConformation && (
        <Conformation
          title={"Verify Driver"}
          msg={`Are you sure to ${props.driveinfo.isVerifiedAsDriver ? 'cancel verification?' : 'Verify?'}`}
          status={props.driveinfo.isVerifiedAsDriver}
          confirm={() => {
            AuthService.verifyDriver(user?.user?._id, props.driveinfo._id).then(
              (data) => {
                if (props.refresh) {
                  props.setshowdriveinfo(!props.showdriveinfo);
                  props.refresh();
                }
              }
            );
          }}
        />
      )}

      {/* Header with close button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => props.setshowdriveinfo(!props.showdriveinfo)}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="space-y-6">
        {/* Profile Section */}
        <div className="flex flex-col items-center space-y-4">
          <div className="relative">
            <ImageS3
              uri={getProfile()}
              style="h-32 w-32 rounded-full ring-4 ring-indigo-50"
            />
            {props.driveinfo.isVerifiedAsDriver && (
              <div className="absolute -bottom-1 -right-1 bg-green-500 rounded-full p-2">
                <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>
          <h2 className="text-2xl font-semibold text-gray-800">{getName()}</h2>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gray-50 p-6 rounded-xl">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <div>
                <p className="text-sm text-gray-500">Phone</p>
                <p className="font-medium">{props?.driveinfo?.mobile}</p>
              </div>
            </div>

            {/* <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <div>
                <p className="text-sm text-gray-500">City</p>
                <p className="font-medium">{props?.driveinfo?.address?.city || 'N/A'}</p>
              </div>
            </div> */}

            {props?.tableFor !== "passenger" && (
              <div className="flex items-center space-x-3">
                <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div>
                  <p className="text-sm text-gray-500">Status</p>
                  <p className={`font-medium ${props.driveinfo.isVerifiedAsDriver ? 'text-green-600' : 'text-red-600'}`}>
                    {props.driveinfo.isVerifiedAsDriver ? 'Verified' : 'Not verified'}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Documents Section */}
          {props?.isDriver && (
            <div className="space-y-4">
              <h3 className="font-semibold text-gray-800 mb-4">Documents</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <p className="text-sm text-gray-500">NID</p>
                  {props?.driveinfo?.docs?.nidFont?.uri ? (
                    <ImageS3
                      uri={props?.driveinfo?.docs?.nidFont?.uri}
                      style="h-32 w-full object-cover rounded-lg shadow-sm"
                    />
                  ) : (
                    <div className="h-32 w-full bg-gray-100 rounded-lg flex items-center justify-center">
                      <p className="text-gray-400">No Image</p>
                    </div>
                  )}
                </div>

                <div className="space-y-2">
                  <p className="text-sm text-gray-500">Driving License</p>
                  {props?.driveinfo?.docs?.drivingLicense?.uri ? (
                    <ImageS3
                      uri={props?.driveinfo?.docs?.drivingLicense?.uri}
                      style="h-32 w-full object-cover rounded-lg shadow-sm"
                    />
                  ) : (
                    <div className="h-32 w-full bg-gray-100 rounded-lg flex items-center justify-center">
                      <p className="text-gray-400">No Image</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Verification Button */}
        {props?.tableFor !== "passenger" && (
          <div className="flex justify-center pt-4">
            <button
              onClick={() => setVisibilityConformation(!visibleConformation)}
              className={`px-6 py-2 rounded-lg font-medium transition-colors duration-200 ${
                props.driveinfo.isVerifiedAsDriver
                  ? 'bg-red-600 hover:bg-red-700 text-white'
                  : 'bg-green-600 hover:bg-green-700 text-white'
              }`}
            >
              {props.driveinfo.isVerifiedAsDriver ? 'Cancel Verification' : 'Verify Driver'}
            </button>
          </div>
        )}

        {/* Assigned Car Info */}
        {props.driveinfo.assignTrip && (
          <div className="mt-6 bg-indigo-50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Assigned Car Info</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Car Name</p>
                <p className="font-medium">{props.driveinfo.assignTrip.vehicleDetails.name}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Car Number</p>
                <p className="font-medium">{props.driveinfo.assignTrip.vehicleDetails.model}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Owner</p>
                <p className="font-medium">
                  {props.driveinfo.assignTrip.owner.firstName} {props.driveinfo.assignTrip.owner.lastName}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Seats</p>
                <p className="font-medium">Max {props.driveinfo.assignTrip.vehicleDetails.seat} seats</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverInfomation;
