import React, { useState, useEffect } from "react";
import VehicleService from "../../services/owner/VehicleService";
import "./index.css";
import ImageS3 from "../../components/common/ImageS3";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import FileService from "../../services/FileService";

const CarInfo = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const [s3PubicUri, setS3PubicUri] = useState(null);
  const [vehicleDocument, setVehicleDocument] = useState(null);
  const [isDocumentUpdated, setIsDocumentUpdated] = useState(false);

  console.log("verifiedStatus === ", props.carInfo.vehicle.verifiedStatus);
  console.log("props.carInfo.vehicle === ", props.carInfo.vehicle._id);

  useEffect(() => {
    setVehicleDocument(props.carInfo.vehicle.docs);
  }, [props.carInfo.vehicle.docs]);

  const verifyVehicle = async () => {
    const vehicle = props.carInfo.vehicle;
    const body = {};
    if (vehicle?.verifiedStatus === "pending") {
      body.verifiedStatus = "verified";
    } else if (vehicle?.verifiedStatus === "verified") {
      body.verifiedStatus = "pending";
    } else {
      return;
    }
    // alert(JSON.stringify(body));

    await VehicleService.verifyVehicle(vehicle?._id, body);

    props?.handleModal(false);
    window.location.reload();
  };

  const handleSave = () => {
    console.log("handleSave")
    window.location.reload();
  }

  const handleUpdate = async (file, type) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await FileService.uploadS3PrivateFile(formData);
        console.log("uploadS3PrivateFile response:", response)
        if (response?.fileUrl) {
          setS3PubicUri(response.fileUrl);
          // Update vehicle docs if needed
          const vehicle = props.carInfo.vehicle;
          const updatedDocs = {
            type,
            url: response.fileUrl
          };
          const res = await VehicleService.updateVehicleDocument(vehicle._id, updatedDocs);
          console.log("updateVehicleDocument response:", res)
          // if success then set the vehicle document
          if (res?.data?.docs) {
            setVehicleDocument(res?.data?.docs);
            setIsDocumentUpdated(true);
          }
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  }

  const CarPhoto = ({ type, title, uri }) => {
    if (!uri) return (
      <div className="mr-2">
        {title && <p className="text-sm font-semibold h-10 capitalize">{title}</p>}

        <div className="w-40 h-28 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors relative">
          <input
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              handleUpdate(file, type);
            }}
          />
          <svg
            className="w-8 h-8 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
      </div>
    );
    return (
      <div className="mr-2">
        {title && <p className="text-sm font-semibold h-10">{title}</p>}

        <div className="relative group">
          <ImageS3 uri={uri} style={"min-w-40 h-28 rounded-lg object-cover"} />

          {/* Edit Button Overlay */}
          <div className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity">
            <label className="cursor-pointer bg-white/90 backdrop-blur-sm rounded-md p-1 shadow-md hover:bg-gray-100 transition-colors border border-gray-200 flex items-center justify-center">
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  handleUpdate(file, type);
                }}
              />
              <svg
                className="w-3 h-3 text-gray-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </label>
          </div>
        </div>
      </div>
    );
  };

  console.log("carinfoooo:", props?.carInfo?.vehicle?.docs);

  return (
    <div>
      <div className="flex flex-row">
        <div className="flex mb-4">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            {/* Car Info Card */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden w-full">
              {/* Card Header */}
              <div className="bg-indigo-50 px-4 py-3 border-b">
                <div className="flex items-center space-x-2">
                  <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M19 4h-4a2 2 0 00-2 2v12a2 2 0 002 2h4M19 4l2 2m-2-2l-2 2m-5-2H9a2 2 0 00-2 2v12a2 2 0 002 2h4" />
                  </svg>
                  <h3 className="text-lg font-semibold text-gray-800">Car Information</h3>
                </div>
              </div>

              {/* Card Content */}
              <div className="p-4 space-y-3">
                {/* Car Details Grid */}
                <div className="grid grid-cols-2 gap-4">
                  {/* Car Model */}
                  <div>
                    <p className="text-sm text-gray-500">Car Model</p>
                    <p className="font-medium text-gray-700">{props.carInfo?.vehicle?.carInfo.model}</p>
                  </div>

                  {/* Seats */}
                  <div>
                    <p className="text-sm text-gray-500">Seats</p>
                    <p className="font-medium text-gray-700">{props.carInfo?.vehicle?.seats}</p>
                  </div>
                </div>

                {/* Number Plate */}
                <div>
                  <p className="text-sm text-gray-500">Registration Number</p>
                  <div className="bg-gray-50 px-2 py-1 rounded">
                    <p className="font-medium text-gray-700">
                      {props.carInfo.vehicle.registration.cityName}{" "}
                      {props.carInfo.vehicle.registration.vehicleCategory}{" - "}
                      {props.carInfo?.vehicle?.registration?.vehicleNumber}
                    </p>
                  </div>
                </div>

                {/* Status */}
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Status</p>
                    <div className="flex items-center space-x-2">
                      <div className={`w-2 h-2 rounded-full ${props.carInfo.vehicle.verifiedStatus === "pending"
                        ? 'bg-yellow-400'
                        : 'bg-green-500'
                        }`}></div>
                      <p className={`text-sm font-medium ${props.carInfo.vehicle.verifiedStatus === "pending"
                        ? 'text-yellow-600'
                        : 'text-green-600'
                        }`}>
                        {props.carInfo.vehicle.verifiedStatus === "pending"
                          ? 'Pending'
                          : 'Verified'}
                      </p>
                    </div>
                  </div>

                  {/* Action Button */}
                  <button
                    onClick={verifyVehicle}
                    className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-colors duration-200 ${props.carInfo.vehicle.verifiedStatus === "pending"
                      ? 'bg-green-600 hover:bg-green-700 text-white'
                      : 'bg-red-600 hover:bg-red-700 text-white'
                      }`}
                  >
                    {props.carInfo.vehicle.verifiedStatus === "pending"
                      ? 'Verify'
                      : 'Remove'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          isDocumentUpdated && (
            <div className="flex">
              <button className="bg-indigo-500 text-white px-4 py-2 rounded-lg h-10" onClick={handleSave}>
                Save
              </button>
            </div>
          )
        }
      </div>

      <div className=" flex">
        <div className="flex flex-col">
          <CarPhoto
            type="vehicleFont"
            title={vehicleDocument?.vehicleFont?.title}
            uri={vehicleDocument?.vehicleFont?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            type="vehicleBack"
            title={vehicleDocument?.vehicleBack?.title}
            uri={vehicleDocument?.vehicleBack?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            type="vehicleNumberPlate"
            title={vehicleDocument?.vehicleNumberPlate?.title}
            uri={vehicleDocument?.vehicleNumberPlate?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            type="smartCard"
            title={vehicleDocument?.smartCard?.title}
            uri={vehicleDocument?.smartCard?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            type="taxToken"
            title={vehicleDocument?.taxToken?.title}
            uri={vehicleDocument?.taxToken?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            type="fitness"
            title={vehicleDocument?.fitness?.title}
            uri={vehicleDocument?.fitness?.uri}
          />
        </div>
      </div>
    </div>
  );
};
export default CarInfo;
