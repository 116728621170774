import React, { useState, useEffect, memo } from "react";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import BookingService from "../../services/BookingService";

// Separate BookingForm component with memo
const BookingForm = memo(({ userType, initialBooking, onUpdate }) => {
  const [booking, setBooking] = useState({ amount: "", type: "fixed" });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setBooking(initialBooking);
  }, [initialBooking]);

  useEffect(() => {
    console.log('booking', booking);
  }, [booking]);

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setBooking(prev => ({ ...prev, amount }));
  };

  const handleTypeChange = (e) => {
    setBooking(prev => ({ ...prev, type: e.target.value }));
  };

  const handleUpdate = async () => {
    if (isEditing) {
      await onUpdate(userType, booking);
    }
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setBooking(initialBooking); // Reset to initial values
    setIsEditing(false);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Booking Setting For {userType}
      </h2>
      <div className="flex flex-col md:flex-row md:items-end space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex-1">
          <label htmlFor={`${userType}-amount`} className="block text-sm font-medium text-gray-700 mb-1">
            Booking Amount
          </label>
          <input
            id={`${userType}-amount`}
            type="number"
            value={booking.amount}
            onChange={handleAmountChange}
            disabled={!isEditing}
            className={`w-full px-3 py-2 border rounded-md ${
              isEditing ? 'border-indigo-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-indigo-500 h-10`}
            placeholder="Enter amount"
            step="0.1" // Allow decimal values
          />
        </div>
        <div className="flex-1">
          <label htmlFor={`${userType}-type`} className="block text-sm font-medium text-gray-700 mb-1">
            Booking Type
          </label>
          <select
            id={`${userType}-type`}
            value={booking.type}
            onChange={handleTypeChange}
            disabled={!isEditing}
            className={`w-full px-3 py-2 border rounded-md ${isEditing ? 'border-indigo-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 h-10`}
          >
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        <div className="flex-none flex space-x-2">
          {isEditing ? (
            <>
              <button
                onClick={handleCancel}
                className="inline-flex items-center px-4 justify-center
                  border border-gray-300 text-sm font-medium rounded-md 
                  text-gray-700 bg-white hover:bg-gray-50 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  transition-colors duration-150 h-10"
              >
                <svg
                  className="h-4 w-4 mr-1.5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="inline-flex items-center px-4 justify-center
                  border border-transparent text-sm font-medium rounded-md 
                  text-green-700 bg-green-100 hover:bg-green-200 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                  transition-colors duration-150 h-10"
              >
                <svg
                  className="h-4 w-4 mr-1.5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Save
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="inline-flex items-center px-4 justify-center
                border border-transparent text-sm font-medium rounded-md 
                text-indigo-700 bg-indigo-100 hover:bg-indigo-200 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                transition-colors duration-150 h-10"
            >
              <svg
                className="h-4 w-4 mr-1.5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

// Main component
const Booking = () => {
  const [bookingData, setBookingData] = useState({
    driver: { amount: "", type: "fixed" },
    passenger: { amount: "", type: "fixed" }
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const data = await BookingService.getBookingData();
      console.log(data);
      setBookingData({
        driver: {
          amount: data?.driver?.bookingValue || "",
          type: data?.driver?.bookingType || "fixed"
        },
        passenger: {
          amount: data?.passenger?.bookingValue || "",
          type: data?.passenger?.bookingType || "fixed"
        }
      });
    } catch (error) {
      console.error("Failed to fetch booking data:", error);
    }
  };

  const handleUpdate = async (userType, bookingData) => {
    try {
      await BookingService.updateBookingData({
        bookingFor: userType,
        bookingType: bookingData.type,
        bookingValue: bookingData.amount,
      });
      await init();
    } catch (error) {
      console.error(`Failed to update ${userType} booking:`, error);
    }
  };

  return (
    <Layout title={sideBarItems.booking.key}>
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Booking Settings</h1>
        <BookingForm
          userType="driver"
          initialBooking={bookingData.driver}
          onUpdate={handleUpdate}
        />
        <BookingForm
          userType="passenger"
          initialBooking={bookingData.passenger}
          onUpdate={handleUpdate}
        />
      </div>
    </Layout>
  );
};

export default Booking;