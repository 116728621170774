import React, { useState } from "react";
import Conformation from "../../components/common/conformation";
import AuthService from "../../services/owner/AuthService";
import useAuthentication from "../../components/HOC/useAuthentication";
import ImageS3 from "../../components/common/ImageS3";

const OwnerInfomation = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const { user } = useAuthentication();

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 max-w-2xl mx-auto" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {visibleConformation && (
        <Conformation
          title={"Verify Owner"}
          msg={`${props?.ownerInfo?.isVerifiedAsOwner ? 'Are you sure to cancel verification of this owner?' : 'Are you sure to verify this owner?'}`}
          status={props?.ownerInfo?.isVerifiedAsOwner}
          confirm={() => {
            AuthService.verifyOwner(user?.user?._id, props?.ownerInfo?._id).then((data) => {
              if (props.refresh) {
                props.setShowOwnerInfo(!props.showOwnerInfo);
                props.refresh();
              }
            });
          }}
        />
      )}

      {/* Header with close button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => props.setShowOwnerInfo(!props.showOwnerInfo)}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="space-y-6">
        {/* Profile Section */}
        <div className="flex flex-col items-center space-y-4">
          <div className="relative">
            <ImageS3
              uri={props.ownerInfo?.profile}
              style="h-32 w-32 rounded-full ring-4 ring-indigo-50"
            />
            {props.ownerInfo?.isVerifiedAsOwner && (
              <div className="absolute -bottom-1 -right-1 bg-green-500 rounded-full p-2">
                <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>
          <h2 className="text-2xl font-semibold text-gray-800">
            {props?.ownerInfo?.name}
            {(props?.ownerInfo?.firstName || props?.ownerInfo?.lastName) && (
              <span className="text-gray-600 text-lg">
                {' '}({props?.ownerInfo?.firstName || ''} {props?.ownerInfo?.lastName || ''})
              </span>
            )}
          </h2>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gray-50 p-6 rounded-xl">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <div>
                <p className="text-sm text-gray-500">Phone</p>
                <p className="font-medium">{props?.ownerInfo?.mobile}</p>
              </div>
            </div>

            {/* <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <div>
                <p className="text-sm text-gray-500">City</p>
                <p className="font-medium">{props?.ownerInfo?.address?.city || 'N/A'}</p>
              </div>
            </div> */}

            <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <div>
                <p className="text-sm text-gray-500">Status</p>
                <p className={`font-medium ${props.ownerInfo?.isVerifiedAsOwner ? 'text-green-600' : 'text-red-600'}`}>
                  {props.ownerInfo?.isVerifiedAsOwner ? 'Verified' : 'Not Verified'}
                </p>
              </div>
            </div>
          </div>

          {/* Verification Button */}
          <div className="flex items-center justify-center">
            <button
              onClick={() => setVisibilityConformation(!visibleConformation)}
              className={`px-6 py-2.5 rounded-lg font-medium transition-colors duration-200 ${
                props.ownerInfo?.isVerifiedAsOwner
                  ? 'bg-red-600 hover:bg-red-700 text-white'
                  : 'bg-green-600 hover:bg-green-700 text-white'
              }`}
            >
              {props.ownerInfo?.isVerifiedAsOwner ? 'Cancel Verification' : 'Verify Owner'}
            </button>
          </div>
        </div>

        {/* Cars Section */}
        {props?.ownerInfo?.vehicles?.length > 0 && (
          <div className="bg-indigo-50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Registered Vehicles</h3>
            <div className="space-y-3">
              {props?.ownerInfo?.vehicles?.map((item) => (
                <div key={item?._id} className="bg-white rounded-lg p-3 shadow-sm hover:shadow-md transition-shadow duration-200">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      {item?.vehicle?.verifiedStatus === "verified" && (
                        <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                      )}
                      <p className="text-sm font-medium text-gray-900">
                        {item?.vehicle?.registration?.cityName} {item?.vehicle?.registration?.vehicleCategory} - {item?.vehicle?.registration?.vehicleNumber}
                      </p>
                    </div>
                    <button
                      onClick={() => props.showVihicleInfo(item)}
                      className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
                    >
                      Manage
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OwnerInfomation;
