import { useRef } from "react";

export const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return [executeScroll, elRef];
};

export const sideBarItems = {
  dashboard: {
    key: "dashboard",
    value: "Dashboard"
  },
  price: {
    key: "price",
    value: "Price list of trip"
  },
  booking: {
    key: "booking",
    value: "Booking Price"
  },
  location: {
    key: "location",
    value: "Locations"
  },
  route: {
    key: "route",
    value: "Bus Route"
  },
  driver: {
    key: "driver",
    value: "Drivers"
  },
  owner: {
    key: "owner",
    value: "Owners"
  },
  passenger: {
    key: "passenger",
    value: "Passengers"
  },
  trip: {
    key: "trip",
    value: "trips"
  },
  transaction: {
    key: "transaction",
    value: 'transaction'
  },
  refund: {
    key: 'refund',
    value: 'refund'
  },
  notifications: {
    key: 'notifications',
    value: 'notifications'
  },
  support: {
    key: 'support',
    value: 'support'
  },
  logout: {
    key: 'logout',
    value: 'Logout'
  }

}


/* 
<option value="initiated">Initiated</option>
<option value="canceled">Canceled</option>
<option value="deny">Deny</option>
<option value="accepted">Accepted</option>
<option value="acceptedByDriver">Accepted By Driver</option>
<option value="running">Running</option>
<option value="completed">Completed</option>
*/

export const tripStatus = {
  initiated: {
    key: "initiated",
    value: "Initiated"
  },
  canceled: {
    key: "canceled", 
    value: "Canceled"
  },
  deny: {
    key: "deny",
    value: "Deny"
  },
  accepted: {
    key: "accepted",
    value: "Accepted"
  },
  acceptedByDriver: {
    key: "acceptedByDriver",
    value: "Accepted By Driver"
  },
  running: {
    key: "running",
    value: "Running"
  },
  completed: {
    key: "completed",
    value: "Completed"
  }
}