import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TripService from "../../services/TripService";

export default function RefundModal({ trip, transaction, onClose }) {
  const [open, setOpen] = useState(true);
  const [password, setPassword] = useState('');
  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const refundNow = async () => {
    setPassword('');
    const bid = await TripService.getBidDetails(trip?.acceptedBid?.bidId);
    const transactionIdForPassenger = bid?.data?.bid?.bookingByPassenger?.transaction;
    const transactionIdForDriver = bid?.data?.bid?.bookingByDriver?.transaction;

    if (trip?.cancelType === 'auto' && transactionIdForPassenger) {
      await TripService.refundToBkash(transactionIdForPassenger);
    } else if (trip?.canceledBy?.passenger && transactionIdForDriver) {
      await TripService.refundToBkash(transactionIdForDriver);
    } else if (trip?.canceledBy?.driver && transactionIdForPassenger) {
      await TripService.refundToBkash(transactionIdForPassenger);
    }
    handleClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-4">
                      Refund Trip Information
                    </Dialog.Title>
                    <div className="mt-2 space-y-4">
                      <div className="flex justify-between text-sm text-gray-500">
                        <span>Driver Name:</span>
                        <span>{trip?.driver?.name}</span>
                      </div>
                      <div className="flex justify-between text-sm text-gray-500">
                        <span>Driver Mobile:</span>
                        <span>{trip?.driver?.mobile}</span>
                      </div>
                      <div className="flex justify-between text-sm text-gray-500">
                        <span>Passenger Name:</span>
                        <span>{trip?.passenger?.name}</span>
                      </div>
                      <div className="flex justify-between text-sm text-gray-500">
                        <span>Passenger Mobile:</span>
                        <span>{trip?.passenger?.mobile}</span>
                      </div>
                    </div>
                    {/* The refund will be transfered to payerAccount number */}
                    <label htmlFor="confirm" className="block text-sm font-medium text-center text-indigo-600 bg-indigo-50 p-3 rounded-lg mb-2 mt-4">
                      <span className="text-green-600">The refund will be transferred to</span> <span className="text-red-600">{transaction?.rawResponse?.payerAccount}</span> <span className="text-green-600">number</span>
                    </label>
                    <div className="mt-6">
                      <label htmlFor="confirm" className="block text-sm font-medium text-gray-700 mb-2">
                        Type 'confirm' to refund
                      </label>
                      <input
                        type="text"
                        id="confirm"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Type confirm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm ${password !== 'confirm' ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={refundNow}
                  disabled={password !== 'confirm'}
                >
                  Confirm Refund
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}