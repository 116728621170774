import React, { useState, useEffect } from "react";
import moment from 'moment';
import { tripStatus } from "../../utils";
import ConfirmDialog from './ConfirmDialog';

const TripEditModal = ({ isOpen, onClose, trip, onSave }) => {
  const [editedTrip, setEditedTrip] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (trip) {
      setEditedTrip(trip)
    }
  }, [trip]);

  if (!isOpen) return null;

  const handleChange = (field, value) => {
    setEditedTrip(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleLocationChange = (locationType, field, value) => {
    setEditedTrip(prev => ({
      ...prev,
      [locationType]: {
        ...prev[locationType],
        [field]: value
      }
    }));
  };

  const handleSaveClick = () => {
    setShowConfirm(true);
  };

  const handleConfirmSave = () => {
    onSave(editedTrip);
    setShowConfirm(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
      <div className="relative top-10 max-h-screen-80 overflow-y-auto p-8 border max-w-4xl shadow-2xl rounded-lg bg-white">
        {/* Header */}
        <div className="flex justify-between items-center mb-6 pb-4 border-b">
          <h3 className="text-2xl font-semibold text-gray-800">Edit Trip Details</h3>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Form Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Trip Type */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Trip Type</label>
            <select
              value={editedTrip?.tripType}
              onChange={(e) => handleChange('tripType', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="forward">Forward</option>
              <option value="u-turn">U-Turn</option>
              <option value="one-way">One Way</option>
              <option value="two-way">Two Way</option>
            </select>
          </div>

          {/* Vehicle Type */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Vehicle Type</label>
            <select
              value={editedTrip?.vehicleType}
              onChange={(e) => handleChange('vehicleType', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="Private Car">Private Car</option>
              <option value="Micro">Micro</option>
              <option value="CNG">CNG</option>
              <option value="Sedan">Sedan</option>
            </select>
          </div>

          {/* Location Section */}
          <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6 p-4 bg-yellow-50 rounded-lg">
            {/* From Location */}
            <div className="space-y-4">
              <h4 className="font-medium text-gray-700">From Location</h4>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-600">Location Name</label>
                <input
                  type="text"
                  value={editedTrip?.fromLocation?.name}
                  disabled
                  onChange={(e) => handleLocationChange('fromLocation', 'name', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-600">District</label>
                <input
                  type="text"
                  value={editedTrip?.fromLocation?.district}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
            </div>

            {/* To Location */}
            <div className="space-y-4">
              <h4 className="font-medium text-gray-700">To Location</h4>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-600">Location Name</label>
                <input
                  type="text"
                  value={editedTrip?.toLocation?.name}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-600">District</label>
                <input
                  type="text"
                  value={editedTrip?.toLocation?.district}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>

          {/* Date and Time Section */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              value={moment(editedTrip?.startDate).format('YYYY-MM-DD')}
              onChange={(e) => handleChange('startDate', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Start Time</label>
            <input
              type="time"
              value={moment(editedTrip?.startTime, 'h:mm A').format('HH:mm')}
              onChange={(e) => handleChange('startTime', moment(e.target.value, 'HH:mm').format('hh:mm A'))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>

          {/* Two-way trip fields */}
          {editedTrip?.tripType === 'two-way' && (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Back Date</label>
                <input
                  type="date"
                  value={moment(editedTrip?.backDate).format('YYYY-MM-DD')}
                  onChange={(e) => handleChange('backDate', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Back Time</label>
                <input
                  type="time"
                  value={moment(editedTrip?.backTime, 'h:mm A').format('HH:mm')}
                  onChange={(e) => handleChange('backTime', moment(e.target.value, 'HH:mm').format('hh:mm A'))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
            </>
          )}

          {/* Additional Trip Info */}
          <div className="md:col-span-2 grid grid-cols-1 gap-6">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">Additional Trip Info</label>
              <textarea
                value={editedTrip?.additionalTripInfo || ''}
                onChange={(e) => handleChange('additionalTripInfo', e.target.value)}
                rows={4}
                className="w-full mt-2 px-3 py-2 border min-h-20 border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Enter any additional information about the trip..."
              />
            </div>
          </div>

          {/* Trip Status */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Trip Status</label>
            <select
              value={editedTrip?.tripStatus}
              onChange={(e) => handleChange('tripStatus', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              {Object.values(tripStatus).map((status) => (
                <option key={status.key} value={status.key}>{status.value}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-8 pt-4 border-t flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveClick}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </div>

      <ConfirmDialog
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={handleConfirmSave}
        title="Save Changes"
        message="Are you sure you want to save these changes? This action cannot be undone."
      />
    </div>
  );
};

export default TripEditModal; 